import React, { Component } from "react";
import "url";
import "isomorphic-fetch";

import InnerForm from "./InnerForm/";
import ErrorMessage from "./ErrorMessage";

import styles from "./styles.module.less";

const forms = [
    "billing",
    "customercare",
    "optout",
    "optout2",
    "reconsidervalue",
    "sales",
    "transfer",
    "other",
];
const labels = [
    "Billing/Servicing & Payments/Pay-offs/Insurances",
    "Customer Care (NOT payment-related issues)",
    "Request to opt out of marketing for GLBA",
    "Request for information or opt out under CCPA (California residents)",
    "Request for reconsideration of value",
    "Sales & Solicitations",
    "Transfer Requests",
    "Other",
];

class ContactForm extends Component {
    state = {
        form: "",
        firstName: "",
        lastName: "",
        companyName: "",
        loName: "",
        email: "",
        emailConfirm: "",
        phone: "",
        message: "",
        city: "",
        state: "",
        zip: "",
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            emailConfirm: "",
            phone: "",
            message: "",
            city: "",
            state: "",
            zip: "",
            form: "",
        },
        complete: false,
        failure: "",
    };

    handleSelect = e => {
        let form = e.target.value;
        if (!forms.includes(form)) form = forms[0];
        this.setState({ form: e.target.value });
    };

    handleChange = e => {
        const {
            target: { name, value },
        } = e;
        this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.isValid()) return;

        const {
            firstName,
            lastName,
            companyName,
            loName,
            loanNumber,
            email,
            emailConfirm,
            phone,
            city,
            state,
            zip,
            message,
            form,
        } = this.state;
        const body = new URLSearchParams();
        body.append("form-name", form);
        body.append("firstName", firstName);
        body.append("lastName", lastName);
        body.append("companyName", companyName);
        body.append("loName", loName);
        body.append("loanNumber", loanNumber);
        body.append("email", email);
        body.append("emailConfirm", emailConfirm);
        body.append("phone", phone);
        body.append("city", city);
        body.append("state", state);
        body.append("zip", zip);
        body.append("message", message);

        fetch("/contact-us/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        })
            .then(() => {
                this.setState({ complete: true });
            })
            .catch(err => {
                this.setState({ complete: false, failure: err.message });
            });
    };

    isValid() {
        const {
            firstName,
            lastName,
            loanNumber,
            email,
            emailConfirm,
            phone,
            city,
            state,
            zip,
            message,
            form,
        } = this.state;
        let isValid = true;
        let errors = {
            firstName: "",
            lastName: "",
            loanNumber: "",
            email: "",
            emailConfirm: "",
            phone: "",
            city: "",
            state: "",
            zip: "",
            message: "",
            form: "",
        };
        let emailValid = email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        );
        let emailConfirmValid = emailConfirm.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        );
        let isPhoneValid = phone.match(
            /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/,
        );
        let isLoanNumberValid = loanNumber && loanNumber.match(/^[0-9]{7,10}$/);
        if (!forms.includes(form)) {
            errors.form = "Must make a selection";
            isValid = false;
        }
        if (!firstName) {
            errors.firstName = "Enter a first name";
            isValid = false;
        }
        if (!lastName) {
            errors.lastName = "Enter a last name";
            isValid = false;
        }
        if (!email) {
            errors.email = "Enter an email address";
            isValid = false;
        } else if (!emailValid) {
            errors.email = "Enter an email address";
            isValid = false;
        }
        if (!emailConfirm) {
            errors.emailConfirm = "Confirm your email address";
            isValid = false;
        } else if (!emailConfirmValid) {
            errors.emailConfirm = "Confirm your email address";
            isValid = false;
        }
        if (emailValid && emailConfirmValid)
            if (email !== emailConfirm) {
                errors.emailConfirm = "Confirm your email address";
                isValid = false;
            }
        if (form.startsWith("optout") && !phone) {
            errors.phone = "Enter a phone number";
            isValid = false;
        } else if (phone && !isPhoneValid) {
            errors.phone =
                "Please enter a valid phone number in the format 555-555-5555";
            isValid = false;
        }
        if ((!city || !state) && !zip) {
            errors.city = "Enter a city and state or zip code";
            isValid = false;
        }
        if (!message) {
            errors.message = "Enter a message";
            isValid = false;
        }
        if (form === "reconsidervalue" && !loanNumber) {
            errors.loanNumber = "Enter a loan number";
            isValid = false;
        } else if (loanNumber && !isLoanNumberValid) {
            errors.loanNumber = "Please enter a valid loan number";
            isValid = false;
        }
        this.setState({ errors });
        return isValid;
    }

    render() {
        const { form, failure, complete, errors } = this.state;
        const hideSelect = failure || complete;
        return (
            <div>
                <div className={styles.ContactUs}>
                    {!hideSelect && (
                        <form>
                            <select
                                value={form}
                                onChange={this.handleSelect}
                                placeholder="Who are you trying to reach?">
                                <option>Who are you trying to reach?*</option>
                                {forms.map((form, i) => (
                                    <option value={form} key={labels[i]}>
                                        {labels[i]}
                                    </option>
                                ))}
                            </select>
                            <ErrorMessage error={errors.form} />
                        </form>
                    )}
                    <div>
                        <form
                            name={form}
                            className={styles.contactForm}
                            method="POST"
                            data-netlify="true"
                            onSubmit={this.handleSubmit}
                            netlify-honeypot="bfield">
                            <div className={styles.bField}>
                                <input type="text" name="bField" />
                            </div>
                            <InnerForm
                                handleChange={this.handleChange}
                                {...this.state}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactForm;
