import React, { Component } from "react";
import { noop } from "lodash";

import ErrorMessage from "../ErrorMessage";
import stateOptions from "./lib/states.json";

import styles from "./../styles.module.less";

class InnerForm extends Component {
    static defaultProps = {
        complete: false,
        handleChange: noop,
        form: "",
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        emailConfirm: "",
        phone: "",
        city: "",
        state: "",
        zip: "",
        message: "",
        errors: {
            name: "",
            companyName: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            zip: "",
            message: "",
        },
        failure: "",
    };

    handleInputChange = e => {
        this.props.handleChange(e);
    };

    renderComplete() {
        return (
            <div className={styles.completeMessage}>
                <h1>Thank you</h1>
                <p>
                    Your message has been successfully sent.
                    <br />
                    We’ll get back to you as soon as possible.
                </p>
            </div>
        );
    }

    renderFailure() {
        return (
            <div className={styles.completeMessage}>
                <h1>Your message could not be delivered</h1>
                <p>
                    We apologize for any inconvenience.
                    <br />
                    Please try again later.
                </p>
            </div>
        );
    }

    renderForm() {
        const {
            form,
            firstName,
            lastName,
            companyName,
            loName,
            loanNumber,
            email,
            emailConfirm,
            phone,
            city,
            state,
            zip,
            message,
            errors,
        } = this.props;
        return (
            <div>
                <div className={styles.formBlock}>
                    <p>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First name*"
                            onChange={this.handleInputChange}
                            value={firstName}
                        />
                        <ErrorMessage error={errors.firstName} />
                    </p>
                    <p>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last name*"
                            onChange={this.handleInputChange}
                            value={lastName}
                        />
                        <ErrorMessage error={errors.lastName} />
                    </p>
                </div>
                <div className={styles.formBlock}>
                    {form.startsWith("optout") ? (
                        <p>
                            <input
                                type="text"
                                name="loName"
                                placeholder="Loan officer in transaction/marketing"
                                onChange={this.handleInputChange}
                                value={loName}
                            />
                        </p>
                    ) : form === "reconsidervalue" ? (
                        <p>
                            <input
                                type="text"
                                name="loanNumber"
                                placeholder="Loan Number*"
                                onChange={this.handleInputChange}
                                value={loanNumber}
                            />
                            <ErrorMessage error={errors.loanNumber} />
                        </p>
                    ) : (
                        <p>
                            <input
                                type="text"
                                name="companyName"
                                placeholder="Company name"
                                onChange={this.handleInputChange}
                                value={companyName}
                            />
                        </p>
                    )}
                    <p>
                        <input
                            type="text"
                            name="phone"
                            placeholder={`Phone number${
                                form.startsWith("optout") ? "*" : ""
                            }`}
                            onChange={this.handleInputChange}
                            value={phone}
                        />
                        <ErrorMessage error={errors.phone} />
                    </p>
                </div>
                <div className={styles.formBlock}>
                    <p>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email address*"
                            onChange={this.handleInputChange}
                            value={email}
                        />
                        <ErrorMessage error={errors.email} />
                    </p>
                    <p>
                        <input
                            type="email"
                            name="emailConfirm"
                            placeholder="Confirm Email address*"
                            onChange={this.handleInputChange}
                            value={emailConfirm}
                        />
                        <ErrorMessage error={errors.emailConfirm} />
                    </p>
                </div>
                <div className={styles.formBlock}>
                    <p className={styles.city}>
                        <input
                            type="city"
                            name="city"
                            placeholder="City*"
                            onChange={this.handleInputChange}
                            value={city}
                        />
                        <ErrorMessage error={errors.city} />
                    </p>
                    <p className={styles.state}>
                        <select
                            name="state"
                            value={state}
                            onChange={this.handleInputChange}
                            placeholder="State*">
                            <option>State*</option>
                            {stateOptions.map((state, i) => (
                                <option value={state.value} key={i}>
                                    {state.label}
                                </option>
                            ))}
                        </select>
                        <ErrorMessage error={errors.state} />
                    </p>
                    <p className={styles.zip}>
                        <input
                            type="zip"
                            name="zip"
                            placeholder="Zip code*"
                            onChange={this.handleInputChange}
                            value={zip}
                        />
                        <ErrorMessage error={errors.zip} />
                    </p>
                </div>
                <p>
                    <textarea
                        name="message"
                        placeholder="Message*"
                        onChange={this.handleInputChange}
                        value={message}
                    />
                    <ErrorMessage error={errors.message} />
                </p>
                <div>
                    <button
                        className={styles.LinkButton}
                        label="Submit"
                        type="submit"
                        onClick={this.handleValidation}>
                        Submit
                    </button>
                </div>
                <div className={styles.disclosure}>
                    <span style={{ fontWeight: "bold" }}>
                        Borrowers Needing Assistance with Your Mortgage:
                    </span>
                    <br />
                    <br />
                    To speak with a mortgage servicing representative about your
                    mortgage,
                    <br />
                    please call{" "}
                    <span style={{ fontWeight: "bold" }}>855-259-0467</span> or
                    <br />
                    email{" "}
                    <a
                        className={styles.orangeLink}
                        href="mailto:servicingdesk@bayeq.com">
                        servicingdesk@bayeq.com
                    </a>
                    . Be sure to include your mortgage loan number to help
                    access information about your account.
                    <br />
                    <br />
                    If your loan is being serviced by Bay Equity LLC and you
                    have a payment which is past due, please follow{" "}
                    <a
                        className={styles.orangeLink}
                        href="https://loansphereservicingdigital.bkiconnect.com/bayequity/"
                        target="_blank"
                        rel="noopener noreferrer">
                        this link
                    </a>{" "}
                    to discuss home loan assistance payment options due to
                    financial hardship.
                </div>
            </div>
        );
    }

    render() {
        const { complete, failure } = this.props;
        if (failure) return this.renderFailure();
        return !!complete ? this.renderComplete() : this.renderForm();
    }
}

export default InnerForm;
